export default {
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
    pageData: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    isNonEmptyString(str) {
      return utilities.isNonEmptyString(str);
    },
    isNonEmptyEditorContent(content) {
      return content && content.blocks && content.blocks.length > 0;
    },
    nlToBr(str) {
      return this.textToSafeHtml(str);
    },
    nl2Br(str) {
      return this.textToSafeHtml(str);
    },
    nl2br(str) {
      return this.textToSafeHtml(str);
    },
    forceLtrInString(str) {
      // split html tags
      const parts = str.split(/(<[^>]*>)/);

      // 2) Regex: Group 1 = Non-RTL run, Group 2 = RTL run
      // (Hebrew ~ \u0590-\u05FF; Arabic ~ \u0600-\u06FF)
      const pattern =
        /([^\u0590-\u05FF\u0600-\u06FF]+)|([\u0590-\u05FF\u0600-\u06FF]+)/g;

      // wrapping
      const replacedParts = parts.map((part) => {
        // skip html tags
        if (/^<[^>]+>$/.test(part)) {
          return part;
        }

        // wrap with spans
        return part.replace(pattern, (match, g1, g2) => {
          //wrap ltr or rtl based on what is detected
          return g1 ? `<span class="force-ltr">${match}</span>`: `<span>${match}</span>` ;
        });
      });

      // join it back
      let html = replacedParts.join("");

      // fix special chase where an LTR span before a BR would show it's spaces on the wrong isde

      // detect the ltr span with br after it
      const fixRegex = /<span class="force-ltr">(.*?)<\/span>(\s*<br[^>]*>)/g;
      html = html.replace(fixRegex, (full, ltrText, brBlock) => {
        // add space iof missing. non-breaking space works best
        if (!/\s$/.test(ltrText)) {
          ltrText += "&nbsp;";
        }
        return `<span class="force-ltr">${ltrText}</span>${brBlock}`;
      });

      return html;
    },
    onLpMounted() {},
  },
  emits: ["lp:sectionReady"],
  mounted() {
    this.$nextTick(() => {
      this.$emit("lp:sectionReady", this);
    });
  },
};
